import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN2-U4-P39-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page39/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "213px",
              left: "303px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "656px",
              left: "608px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "422px",
              left: "303px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "213px",
              left: "608px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "656px",
              left: "303px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "422px",
              left: "608px",
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
        ],
        answers: ["2-3", "0-1", "4-5"],
        initialValue: ["2-3"],
      },
      Layout: `
          <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page39/E1/a.jpg' style="max-width: 90%;" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
      `,
    },
  },
};

export default json;
