import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'LQTAMN2-U2-P51-E1',
    audio: '',
    video: '',
    titleQuestion: [
      {
        num: '',
        title: ``,
        color: '',
      },
    ],
    component: PaintColorType,
    isAllowSubmit: false,
    questionImage: [],
    questionSVG: [
      [
        {
          viewBox: '0 0 1096 1971',
          style: {
            width: 400,
          },
          g: `
					<path id="6" style="cursor: pointer; fill:#ffffff; stroke:black;" 
<path fill="#751330" opacity="1.000000" stroke="none" 
	d="
M153.043930,698.951416 
	C153.051407,698.782715 153.058884,698.614014 153.216370,697.837158 
	C153.512024,695.140503 153.657684,693.052002 153.803314,690.963440 
	C153.828918,690.523132 153.854523,690.082886 153.913010,688.862427 
	C153.981842,685.930969 153.925919,683.773682 154.068207,681.629517 
	C155.277313,663.408264 156.522507,645.189514 157.756897,626.969971 
	C157.783798,626.525757 157.810699,626.081482 157.889221,624.871094 
	C158.634399,613.539795 159.271164,602.970459 160.045502,592.411255 
	C160.431931,587.141907 161.118362,581.895264 161.583344,576.630798 
	C162.368210,567.745056 163.084900,558.853333 163.830231,549.964111 
	C163.857605,549.525818 163.884979,549.087585 163.953735,547.900757 
	C164.593491,541.099365 165.191849,535.046387 165.851196,528.544128 
	C167.171875,514.387695 168.431595,500.680573 169.691299,486.973480 
	C169.757080,486.233765 169.822861,485.494019 169.927368,483.945740 
	C170.170853,481.341461 170.400955,479.548157 170.576172,477.749542 
	C171.674500,466.476166 172.760590,455.201569 173.850739,443.927399 
	C173.872192,443.503326 173.893646,443.079285 173.955505,441.917542 
	C175.604996,426.443268 177.214050,411.706696 178.823120,396.970093 
	C178.868271,396.235138 178.913406,395.500153 178.967606,394.141846 
	C178.960892,393.348999 178.945114,393.179565 178.929321,393.010101 
	C179.296188,387.908966 179.459747,382.783020 180.077927,377.712524 
	C180.988602,370.243073 182.341202,362.828125 183.291519,355.362732 
	C184.625992,344.879303 185.776718,334.372345 186.982605,323.872772 
	C188.113724,314.024139 189.183014,304.168335 190.336182,294.322327 
	C191.853073,281.370789 193.347534,268.415802 194.997406,255.480881 
	C196.437408,244.191284 198.079300,232.927444 199.790192,221.276489 
	C200.983185,221.495483 202.533112,221.834732 202.974060,222.723923 
	C208.105927,233.072556 213.043015,243.517548 218.094788,253.906326 
	C219.810913,257.435516 221.704590,260.878326 223.757721,264.944519 
	C224.341980,266.019684 224.685654,266.510956 225.029327,267.002228 
	C225.029343,267.002228 224.994598,267.001740 224.992096,267.391907 
	C225.083725,268.934021 224.863327,270.232483 225.320953,271.215149 
	C229.573395,280.346497 233.901413,289.443634 238.334396,298.488525 
	C238.838272,299.516571 240.121796,300.162506 241.043793,300.985626 
	C241.043793,300.985626 241.002228,301.004456 240.992218,301.391846 
	C242.342392,304.504974 243.702576,307.230713 245.053711,309.976013 
	C245.044662,309.995575 245.043747,309.958710 245.024292,310.336365 
	C245.669220,311.813538 246.333603,312.913086 246.997772,314.007294 
	C246.997559,314.001984 246.988937,313.996552 246.989502,314.352234 
	C247.366730,315.521393 247.743378,316.334930 248.207932,317.486206 
	C260.173798,345.516479 272.051788,373.208923 283.953308,401.274597 
	C284.534698,404.211426 284.783936,406.892212 285.704926,409.317963 
	C289.315002,418.826416 293.077850,428.278687 296.937866,437.688690 
	C297.589874,439.278168 299.000092,440.556610 300.035004,441.993561 
	C300.010040,442.008331 300.038239,441.957825 300.014618,442.374237 
	C300.604492,447.334625 300.783478,452.056946 305.072113,455.234924 
	C305.100067,455.365662 305.072205,455.631561 305.041138,456.064636 
	C306.491821,461.284912 307.884003,466.103058 309.517456,470.837982 
	C309.932953,472.042419 311.114716,472.982544 311.986877,474.210571 
	C312.029358,474.377075 312.052429,474.719940 312.023193,475.138855 
	C312.700195,477.418610 313.406464,479.279510 314.342590,481.457764 
	C316.401489,487.182404 318.230499,492.589630 320.040100,498.422974 
	C320.764435,503.887970 320.217316,509.387146 325.040894,512.986694 
	C325.026947,513.007263 325.035187,512.960938 325.016235,513.401611 
	C324.561554,519.593323 326.757416,524.467712 330.024719,528.993286 
	C330.009338,529.006592 330.021301,528.968811 330.008636,529.403625 
	C328.968842,536.875671 333.116394,542.694214 334.660400,549.124390 
	C335.016876,550.608948 336.798340,551.751404 337.939819,553.368164 
	C337.979828,554.107239 338.004089,554.529541 337.744934,555.213745 
	C337.968018,560.749573 336.898895,566.353149 341.121735,570.817688 
	C342.067871,571.817993 342.406128,573.481812 342.797211,574.902649 
	C344.241364,580.150024 345.528625,585.441345 347.034393,590.670166 
	C347.292999,591.568115 348.329926,592.241943 349.195923,593.140015 
	C349.621246,593.472717 349.743042,593.737488 349.550964,594.237427 
	C349.232330,594.942322 349.112671,595.465088 348.830139,596.081482 
	C348.776520,596.452576 348.885834,596.729919 348.995117,597.420654 
	C350.348206,601.544861 351.701294,605.255737 353.035217,608.979492 
	C353.016052,608.992310 353.045990,608.971008 353.021301,609.416260 
	C353.998169,616.194336 354.837219,622.559570 356.090363,628.842163 
	C356.522339,631.007935 357.986084,632.967957 358.978333,635.410645 
	C359.661102,637.561951 360.344055,639.324585 361.056793,641.232422 
	C361.086548,641.377625 361.047699,641.671631 361.022980,642.132874 
	C361.682678,645.730469 362.367096,648.866882 363.025330,651.993469 
	C362.999146,651.983704 363.048523,651.957336 363.015869,652.212036 
	C362.974823,652.637695 362.966400,652.808716 362.971436,653.356934 
	C362.990692,654.488708 362.996490,655.243103 362.740723,656.029358 
	C362.281586,656.253723 362.083984,656.446350 361.886353,656.638916 
	C362.611725,656.748169 363.337097,656.857483 364.041321,656.987732 
	C364.020172,657.008728 364.030304,656.950012 364.009949,657.393372 
	C365.337555,666.090881 366.856659,674.321777 367.979828,682.606384 
	C368.895508,689.360657 369.337616,696.179077 369.982239,703.229492 
	C369.976105,703.663757 369.977448,703.837463 369.988281,704.005676 
	C369.997803,704.000183 370.007538,703.981323 369.750427,703.921021 
	C369.317230,703.859863 369.141174,703.859009 368.783264,703.841553 
	C368.601379,703.824951 368.240051,703.878418 368.013123,703.683167 
	C367.222748,703.343079 366.659302,703.198181 365.824585,702.774048 
	C362.751465,702.920593 359.949677,703.346375 356.750061,703.833557 
	C355.548920,703.915100 354.745575,703.935242 353.730896,703.680603 
	C350.644958,701.712646 347.928528,700.870239 345.627289,704.855042 
	C339.146027,704.961121 333.134521,704.998352 326.642212,705.032349 
	C305.093262,705.028259 284.025024,705.027527 262.492065,705.067139 
	C242.658539,704.389099 223.288452,703.703003 203.921890,702.929321 
	C199.137451,702.738159 194.361984,702.292786 189.586548,701.912170 
	C177.404419,700.940918 165.224640,699.940308 153.043930,698.951416 
z"/>




					<path id="5" style="cursor: pointer; fill:#ffffff; stroke:black;" 
	d="
M190.991486,237.302460 
	C195.338974,239.948624 193.060547,244.324753 192.697174,247.787598 
	C190.781418,266.043945 188.295807,284.240265 186.028976,302.460022 
	C183.761215,320.687225 181.400162,338.903931 179.302734,357.150818 
	C177.694778,371.139252 176.511108,385.175934 174.997589,399.175873 
	C173.164993,416.127533 171.085938,433.052826 169.300491,450.009186 
	C168.067947,461.714813 167.248978,473.463531 166.071121,485.175476 
	C164.563751,500.163849 162.782684,515.124939 161.310471,530.116577 
	C160.387283,539.517273 159.865845,548.956726 159.073456,558.371155 
	C157.837448,573.056519 156.451614,587.729614 155.268723,602.419128 
	C154.430420,612.829529 153.717972,623.252686 153.133972,633.680542 
	C152.038925,653.232727 151.048096,672.790894 150.087646,692.350281 
	C149.911789,695.931641 148.275833,697.511353 144.304962,697.213379 
	C119.627678,694.476624 95.372543,691.660645 71.120293,688.820068 
	C70.304901,688.724609 69.518578,688.381104 68.268509,687.672974 
	C65.863037,687.138977 63.907757,687.085144 61.952477,687.031311 
	C61.507324,687.005981 61.062168,686.980713 60.047226,686.624878 
	C56.459602,685.535950 53.472359,684.335632 50.416660,684.124084 
	C46.002308,683.818420 42.163193,683.351440 41.725128,677.828918 
	C41.825466,677.422119 41.925804,677.015320 42.463722,676.228088 
	C43.593586,672.888489 44.285866,669.929260 44.978142,666.970093 
	C44.978142,666.970093 45.002747,666.998474 45.263191,666.795471 
	C45.668072,665.706421 45.812508,664.820312 45.956940,663.934265 
	C45.956944,663.934265 45.988964,663.996643 46.257778,663.879639 
	C47.329617,660.739014 48.132645,657.715393 48.935669,654.691833 
	C48.935673,654.691833 48.940304,654.352356 49.306541,654.014771 
	C51.445129,647.439392 53.217476,641.201660 54.989815,634.963867 
	C54.989811,634.963867 55.005768,635.003906 55.249557,634.842407 
	C55.647697,634.098083 55.802048,633.515198 55.956390,632.932312 
	C55.956390,632.932312 55.991573,632.991821 56.246696,632.798157 
	C56.647556,631.710083 56.793293,630.815674 56.939034,629.921265 
	C56.939037,629.921265 56.979187,629.990967 57.274853,629.837646 
	C59.359104,623.411011 61.147694,617.137756 62.936275,610.864441 
	C62.936272,610.864441 62.961037,610.417358 63.235558,610.086914 
	C63.759815,609.532715 63.901035,609.253967 63.933731,608.920166 
	C63.933731,608.920166 63.983994,608.991577 64.251053,608.773071 
	C64.659492,607.348755 64.800865,606.143005 64.942245,604.937195 
	C64.942253,604.937195 64.978996,605.005981 65.279678,604.854980 
	C69.871979,601.697632 68.953888,597.187317 68.974075,592.947876 
	C68.974075,592.947876 68.999565,593.000122 69.304573,592.846741 
	C70.373878,590.102112 71.138176,587.510803 71.902481,584.919556 
	C71.902481,584.919556 71.909225,585.012817 72.233810,584.848267 
	C75.016571,576.778320 77.474739,568.872986 79.932907,560.967590 
	C79.932899,560.967590 79.973305,560.945740 80.323364,560.907166 
	C82.034996,557.995544 83.715340,555.227234 84.700493,552.230469 
	C88.141518,541.762878 91.393303,531.231750 94.577293,520.682556 
	C95.033768,519.170166 94.722130,517.425964 94.765976,515.789001 
	C94.850906,515.392334 94.935837,514.995728 95.300812,514.181763 
	C95.812019,513.543152 95.934166,513.272583 95.947296,512.952759 
	C95.947296,512.952759 95.954788,512.957153 96.311386,512.917480 
	C98.756615,507.437775 101.073174,502.071594 102.867126,496.536072 
	C104.522102,491.429352 105.616196,486.140839 106.957344,480.932404 
	C106.957344,480.932404 106.983788,481.001373 107.318558,480.938232 
	C108.106270,479.584625 108.559219,478.294159 109.285988,476.764038 
	C110.014412,474.327118 110.469017,472.129791 110.923615,469.932495 
	C110.923622,469.932495 110.942970,470.016296 111.273529,469.835022 
	C112.038719,467.635559 112.473343,465.617371 112.907967,463.599152 
	C112.907967,463.599152 112.911247,463.315430 113.188164,463.051025 
	C113.592049,462.482025 113.719017,462.177429 113.845978,461.872864 
	C113.845978,461.872864 113.768394,461.841766 114.181076,461.813660 
	C115.007095,459.706879 115.420441,457.628235 115.833778,455.549561 
	C115.883858,455.203705 115.933929,454.857819 116.403107,454.193115 
	C119.188622,447.840240 121.772270,441.879639 123.845711,435.746582 
	C125.247406,431.600433 125.930649,427.211365 126.935242,422.930969 
	C126.935249,422.930969 126.973938,423.004974 127.286484,422.838165 
	C128.046738,420.759888 128.494431,418.848419 128.942123,416.936951 
	C128.942123,416.936951 128.947495,417.010986 129.298279,416.942139 
	C130.081696,416.428467 130.732742,416.058350 130.914322,415.527588 
	C133.858536,406.921631 136.774887,398.305542 139.585388,389.655334 
	C139.828674,388.906555 139.283722,387.901672 139.104935,387.015747 
	C139.210541,386.465393 139.316147,385.915039 139.937256,385.005554 
	C140.596771,383.982574 140.740799,383.318695 140.884827,382.654846 
	C140.884811,382.654846 140.872452,382.331268 141.302719,382.103149 
	C142.123047,380.221527 142.513107,378.568024 142.903168,376.914520 
	C142.903168,376.914520 142.861832,376.953217 143.252457,376.891357 
	C144.100159,374.580139 144.557236,372.330811 145.014313,370.081451 
	C154.081390,343.776855 163.120728,317.462646 172.225143,291.170959 
	C178.405334,273.323853 184.676804,255.508316 190.991486,237.302460 
z"/>



					<path id="4" style="cursor: pointer; fill:#ffffff; stroke:black;" 
	d="
M403.348206,1882.954468 
	C403.339020,1884.032715 403.329803,1885.111084 403.418610,1886.583252 
	C404.021881,1899.092773 404.527161,1911.208496 405.064301,1924.087646 
	C401.446503,1923.125244 398.450562,1922.399170 395.496033,1921.531860 
	C342.599945,1906.002441 289.546082,1890.983643 236.870743,1874.737671 
	C198.275650,1862.834351 160.053757,1849.673096 121.924332,1836.332764 
	C107.544403,1831.301880 93.986580,1823.963989 79.880150,1818.095459 
	C67.971535,1813.141235 63.366837,1804.480835 62.911549,1791.789551 
	C60.905724,1735.877563 58.204262,1679.990723 56.030807,1624.084106 
	C54.633854,1588.150879 53.643795,1552.201050 52.604900,1516.254883 
	C51.584862,1480.961060 50.691784,1445.663330 49.819153,1410.365479 
	C48.769695,1367.915161 47.759499,1325.463745 46.810234,1283.011108 
	C46.087921,1250.708130 45.485806,1218.402466 44.832821,1186.098022 
	C44.136078,1151.628662 43.401726,1117.160034 42.758022,1082.689697 
	C42.011562,1042.717041 41.234642,1002.744629 40.671967,962.769226 
	C39.884174,906.800537 39.221115,850.829895 38.627300,794.858765 
	C38.346256,768.368408 38.339146,741.875122 38.230049,715.383057 
	C38.193119,706.415039 38.224197,697.446777 38.224197,688.483887 
	C75.350693,692.928589 111.261780,697.227844 147.983353,701.645020 
	C153.520584,702.117554 158.247314,702.472168 163.349869,702.909729 
	C164.491257,702.984863 165.256851,702.976929 166.387253,703.132812 
	C171.411835,703.624023 176.071594,703.951538 181.139053,704.440247 
	C182.711838,704.635071 183.876938,704.668640 185.416687,704.832336 
	C188.202164,705.010803 190.612961,705.059204 193.307526,705.234375 
	C194.197159,705.309753 194.803009,705.258484 195.779480,705.449036 
	C200.437836,705.873962 204.725616,706.057007 209.401855,706.415833 
	C211.209503,706.616699 212.628677,706.641724 214.464569,706.790405 
	C218.266785,706.991821 221.652298,707.069702 225.356583,707.322754 
	C226.154694,707.481995 226.634018,707.466064 227.540131,707.626587 
	C248.552475,708.388977 269.136017,709.068604 289.724640,709.512634 
	C297.493896,709.680298 305.274963,709.302979 313.378845,709.239624 
	C314.140656,709.217896 314.574219,709.130676 315.347656,709.155640 
	C326.402069,708.846252 337.116516,708.424622 348.228149,708.061523 
	C349.420227,708.037231 350.215118,707.954468 351.466797,707.951965 
	C358.940338,707.686951 365.957123,707.341736 372.973907,706.996460 
	C372.973907,706.996460 372.988556,707.016357 373.049866,707.474609 
	C373.370331,712.243835 373.771912,716.551758 373.863983,720.866394 
	C374.257965,739.335571 374.560608,757.806763 374.896851,776.277222 
	C375.230133,794.584351 375.551025,812.891785 375.900940,831.198608 
	C376.228577,848.339722 376.597473,865.480042 376.929108,882.621094 
	C377.263947,899.928101 377.557373,917.235901 377.902100,934.542664 
	C378.226837,950.846680 378.609985,967.149536 378.939697,983.453430 
	C379.279510,1000.257812 379.569244,1017.063171 379.911774,1033.867432 
	C380.227203,1049.343262 380.583527,1064.818237 380.922180,1080.293579 
	C381.599365,1111.239258 382.274597,1142.184814 382.955170,1173.130493 
	C383.273590,1187.611084 383.599213,1202.091553 383.927460,1216.571899 
	C384.599030,1246.197021 385.251953,1275.822754 385.955261,1305.447266 
	C386.595337,1332.406738 387.275604,1359.365234 387.967529,1386.323486 
	C388.954315,1424.768677 389.961914,1463.213501 390.971832,1501.658203 
	C391.854889,1535.274170 392.740265,1568.890259 393.649231,1602.505615 
	C394.427887,1631.301636 395.140167,1660.100220 396.064056,1688.891724 
	C397.318726,1727.989624 398.693054,1767.083984 400.131897,1806.175659 
	C400.596863,1818.808472 401.426941,1831.428101 402.023499,1844.412231 
	C402.013458,1845.495117 402.069885,1846.219360 402.046936,1847.363281 
	C402.024780,1849.201294 402.082031,1850.619751 402.061218,1852.495850 
	C402.438171,1862.953857 402.893188,1872.954224 403.348206,1882.954468 
z"/>
					<path id="3" style="cursor: pointer; fill:#ffffff; stroke:black;" 
	d="
M820.865234,82.008705 
	C820.865173,82.008713 821.014465,82.456978 820.961426,83.073441 
	C820.713623,84.969826 820.195679,86.298317 820.379578,87.521309 
	C821.210144,93.046135 822.306580,98.530525 823.175537,104.050209 
	C823.858032,108.385551 824.348022,112.751205 824.931519,117.409615 
	C824.929321,118.124374 824.919189,118.532898 824.639160,119.262299 
	C824.267456,137.158218 824.157288,154.733246 824.100769,172.308426 
	C824.098938,172.884171 824.643250,173.461685 824.942871,174.352097 
	C824.953613,175.084183 824.954529,175.502579 824.677490,176.237442 
	C823.815552,184.897919 823.231506,193.241913 822.688599,200.997665 
	C818.181519,200.997665 815.090759,200.997665 811.626099,200.994171 
	C810.505310,200.985031 809.758362,200.979385 808.724426,200.692963 
	C804.620972,200.265701 800.804443,200.119247 796.563721,199.972031 
	C794.446594,199.944412 792.753662,199.917557 790.789062,199.637604 
	C787.667358,199.234665 784.817200,199.084839 781.601807,198.945526 
	C780.506104,198.944901 779.775757,198.933762 778.734558,198.654480 
	C774.949951,198.246292 771.476196,198.106247 767.592712,197.960602 
	C765.826599,197.944824 764.470154,197.934647 762.994080,197.555084 
	C756.888916,197.106186 750.903442,197.026657 744.559631,196.949738 
	C743.483643,196.940033 742.765991,196.927734 741.759827,196.658691 
	C733.462585,195.610229 725.468201,194.474075 717.440430,194.138367 
	C711.281921,193.880844 705.081116,194.632889 698.594177,194.936996 
	C697.882935,194.917297 697.476685,194.896530 696.771851,194.621429 
	C693.399536,193.915070 690.338684,193.220245 687.249695,193.057236 
	C678.551819,192.598221 669.844055,192.287933 661.136292,192.082932 
	C659.086487,192.034653 657.021423,192.632111 654.571411,192.945236 
	C653.132202,192.949097 652.084839,192.940216 650.837402,192.700668 
	C649.748657,192.620010 648.860168,192.770020 647.657227,192.926300 
	C646.924561,192.937180 646.506348,192.941772 645.766296,192.673889 
	C632.629639,192.272354 619.814819,192.143265 606.534302,192.005646 
	C567.046143,192.005936 528.023682,192.014771 488.532532,192.012985 
	C477.086945,192.006760 466.109741,191.971161 455.133453,192.056488 
	C453.384552,192.070084 451.639862,192.626511 449.540649,192.938187 
	C448.479797,192.950287 447.771545,192.956192 446.812500,192.711288 
	C445.031281,192.624390 443.500854,192.788284 441.537292,192.961578 
	C439.079071,192.964798 437.053955,192.958649 434.730560,192.678467 
	C429.961304,192.607117 425.490387,192.809799 420.654724,193.005310 
	C419.196533,193.305923 418.102997,193.613693 416.540436,193.933350 
	C391.324493,194.624008 366.574799,195.214798 341.831787,196.013916 
	C326.475494,196.509872 311.128510,197.302689 295.778931,197.997421 
	C281.532013,198.642227 267.282959,199.255463 253.042572,200.025482 
	C237.325363,200.875366 221.620499,201.965836 205.897934,202.680527 
	C204.649597,202.737289 202.800949,200.920197 202.148651,199.540298 
	C201.508316,198.185684 201.945084,196.270630 202.070908,194.613815 
	C202.678238,186.617584 203.576950,178.635529 203.916000,170.629120 
	C204.254089,162.645065 203.604187,154.605331 204.187561,146.648697 
	C205.093781,134.288605 207.366806,122.002800 207.808151,109.640800 
	C208.424316,92.381279 210.986923,75.439293 214.249619,58.574638 
	C214.652115,56.494141 217.276077,54.291401 219.409821,53.248920 
	C221.665161,52.147034 224.557816,52.144695 227.185822,52.052052 
	C233.101425,51.843498 239.037567,52.087280 244.942825,51.751076 
	C250.875015,51.413334 256.781494,50.053524 262.696472,50.071011 
	C331.636749,50.274853 400.576691,50.620190 469.516144,51.025017 
	C479.726227,51.084976 489.931915,51.703705 500.142151,51.963356 
	C527.717102,52.664608 555.293640,53.302517 582.869080,53.984241 
	C595.326294,54.292210 607.782104,54.655647 620.239075,54.973789 
	C634.029053,55.325981 647.819519,55.656715 661.609924,55.992115 
	C663.108826,56.028572 664.612976,55.966099 666.107178,56.064186 
	C685.773560,57.355148 705.436768,58.693825 725.105408,59.947781 
	C728.591248,60.170017 732.120667,59.820972 735.591553,60.145710 
	C752.055603,61.686096 768.523376,63.217403 784.953918,65.070084 
	C792.155823,65.882149 799.281677,67.401184 806.423035,68.703506 
	C809.683777,69.298164 812.898438,70.145409 816.134033,70.878082 
	C816.134033,70.878082 815.997620,70.993599 816.019958,71.332062 
	C817.649902,75.116577 819.257568,78.562637 820.865234,82.008705 
M355.768829,103.820625 
	C355.768829,103.820625 355.615601,103.683571 356.285492,103.619843 
	C356.465424,103.019508 356.645355,102.419174 356.796051,101.934204 
	C356.796051,101.934204 356.758545,101.821251 357.392365,101.880104 
	C357.775879,101.512688 358.159424,101.145279 358.542938,100.777863 
	C358.017670,100.536804 357.492371,100.295746 356.987793,100.015251 
	C356.987793,100.015251 356.945404,100.028900 357.047058,99.317787 
	C357.852509,97.893913 358.612396,96.441872 359.472473,95.051788 
	C361.536957,91.714935 360.296234,89.941032 356.786621,89.150803 
	C356.786621,89.150803 356.822754,89.154991 356.949890,88.375168 
	C355.583038,87.304764 354.216187,86.234360 352.948975,85.200890 
	C352.948975,85.200890 352.843079,85.209785 352.810028,84.381363 
	C348.937683,82.160385 341.051483,82.393867 338.279236,85.066383 
	C337.515991,85.802132 337.501801,87.314835 337.009064,88.793022 
	C336.865143,88.992836 336.671875,89.113922 335.621216,89.335648 
	C333.042206,93.234886 333.311310,98.736748 336.567413,101.234444 
	C336.736938,101.657158 336.906464,102.079880 337.179810,103.133675 
	C337.483002,103.410454 337.786163,103.687233 338.040985,103.902214 
	C338.040985,103.902214 338.107086,103.859901 338.086517,104.605446 
	C342.529510,110.342163 351.279358,110.507935 353.990540,104.980080 
	C353.990540,104.980080 353.913239,104.947334 354.565338,104.942337 
	C354.936279,104.506943 355.307190,104.071556 355.768829,103.820625 
M253.003143,93.998779 
	C253.003143,93.998779 253.004730,94.010918 253.025284,93.232048 
	C251.935104,91.890846 250.844940,90.549652 249.752304,89.154037 
	C249.752304,89.154037 249.800247,89.179863 249.928116,88.411758 
	C248.654160,83.618065 246.435928,79.517105 241.949203,77.026909 
	C241.949203,77.026909 241.758514,77.263550 241.861496,76.517944 
	C240.988922,75.730446 240.116348,74.942947 239.243774,74.155449 
	C238.503036,75.104424 237.762299,76.053406 237.004227,77.004906 
	C237.004227,77.004906 237.006027,77.022484 236.259491,77.157135 
	C235.877396,77.643295 235.164902,78.127686 235.162277,78.615868 
	C235.115646,87.305107 235.023804,95.999191 235.313141,104.679848 
	C235.358322,106.034866 237.224518,107.329185 238.248657,108.651588 
	C239.166031,107.320488 240.778793,106.037384 240.874588,104.649574 
	C241.204315,99.872520 240.725372,95.033218 241.163727,90.273750 
	C241.307648,88.711227 243.306244,87.319542 244.774826,86.100159 
	C244.958206,86.315613 245.049484,86.564796 245.008133,87.636681 
	C244.924942,90.537788 245.849991,92.649727 249.046982,92.986725 
	C249.046982,92.986725 249.092972,92.878975 249.031586,93.624001 
	C249.742081,94.374611 250.452591,95.125229 251.156021,95.962959 
	C251.156021,95.962959 251.121368,95.882790 251.030884,96.657463 
	C252.378845,99.623047 253.344086,102.868988 255.228561,105.441566 
	C256.243073,106.826538 258.951111,107.799355 260.667542,107.526588 
	C261.673676,107.366699 262.722809,104.442543 262.902008,102.675575 
	C263.469330,97.081810 264.141113,91.415459 263.787994,85.839539 
	C263.534943,81.843628 264.507721,76.868988 258.923737,73.415260 
	C258.152039,76.287247 257.238342,78.387184 257.085968,80.540985 
	C256.816254,84.352669 257.219543,88.214523 256.904053,92.019135 
	C256.780670,93.507217 255.478363,94.897530 254.720642,96.268715 
	C254.720642,96.268715 254.742325,96.208359 254.857254,95.528435 
	C254.235809,95.016502 253.614365,94.504562 253.003143,93.998779 
M273.005676,105.977417 
	C273.005676,105.977417 273.053345,105.878799 273.276276,106.544479 
	C275.674469,109.053543 278.207550,109.814003 281.600067,107.001137 
	C281.998932,106.920380 282.397827,106.839622 283.277313,107.250610 
	C285.227753,107.124588 287.178223,106.998573 288.988647,106.972389 
	C288.988647,106.972389 289.120209,106.858055 289.123718,107.512512 
	C291.523041,108.196609 292.405518,107.513588 292.354614,104.835136 
	C292.301239,102.026131 292.058533,99.220345 292.020264,96.411499 
	C291.960907,92.049286 292.004608,87.685677 292.004608,84.253532 
	C288.277893,83.729668 285.710266,83.389153 283.149139,83.005135 
	C272.850098,81.460907 267.035370,84.293213 265.729431,94.936089 
	C265.325989,98.224098 264.801758,102.868538 269.976471,103.901474 
	C269.976471,103.901474 270.231049,103.738258 270.152008,104.485535 
	C271.132416,104.960907 272.112854,105.436279 273.005676,105.977417 
M315.930634,97.135918 
	C316.232605,94.030281 316.534576,90.924637 317.017670,87.917740 
	C317.017670,87.917740 316.827271,87.841011 317.716248,87.953720 
	C325.243652,88.030151 325.986786,88.767654 326.010010,96.160141 
	C326.019348,99.143211 325.750061,102.169853 326.188782,105.089317 
	C326.382843,106.380821 328.021515,107.455254 329.004395,108.628235 
	C329.986877,107.455383 331.813721,106.277466 331.806702,105.110550 
	C331.775055,99.848534 331.271912,94.585152 330.781281,89.336983 
	C330.735077,88.842575 329.589203,88.450974 329.002869,88.000580 
	C329.002869,88.000580 328.982544,88.046516 328.966400,87.404747 
	C328.564941,86.971672 328.163513,86.538605 327.879303,86.121941 
	C327.879303,86.121941 327.816437,86.222267 327.882385,85.477348 
	C324.743011,81.226944 319.050720,80.936371 314.937256,83.454041 
	C307.828766,83.097633 302.729034,82.814140 297.625336,82.684441 
	C297.414185,82.679070 297.166382,84.114151 296.948425,84.961487 
	C296.948425,84.961487 296.867981,84.936264 296.117706,85.178818 
	C295.620911,89.880844 294.721619,94.590309 294.808350,99.281540 
	C294.847778,101.414886 291.813080,105.090698 297.137604,106.323364 
	C297.739502,106.210205 298.341400,106.097038 299.685608,105.867935 
	C299.796295,102.423508 300.046478,98.976379 299.979980,95.535370 
	C299.907471,91.784279 301.230103,88.497185 304.983856,88.212120 
	C309.356232,87.880089 310.076569,91.506294 310.042908,95.180122 
	C310.009369,98.836876 309.960114,102.520424 310.385498,106.137207 
	C310.505371,107.156288 312.269470,107.981979 313.279419,108.896378 
	C313.787781,107.869736 314.296143,106.843102 314.865784,105.907639 
	C314.865784,105.907639 314.756989,105.923019 315.444611,105.482124 
	C315.585236,102.989807 315.725891,100.497498 315.930634,97.135918 
z"/>

					<path id="2" style="cursor: pointer; fill:#ffffff; stroke:black;" 
	d="
M203.222244,216.928925 
	C202.259750,214.222107 201.297241,211.515305 200.133865,208.243591 
	C214.697998,207.397598 228.293488,206.578827 241.892517,205.823990 
	C269.973389,204.265320 298.046783,202.534622 326.140900,201.264725 
	C350.422913,200.167145 374.725067,199.430496 399.025940,198.854828 
	C434.654083,198.010803 470.287323,197.338821 505.921631,196.810852 
	C531.736450,196.428375 557.559814,196.050507 583.373352,196.298782 
	C615.993042,196.612488 648.619873,197.191742 681.219177,198.345703 
	C713.811768,199.499405 746.382874,201.352020 778.946045,203.203629 
	C793.383057,204.024521 807.768921,205.718307 822.198303,206.724304 
	C825.055359,206.923508 826.222656,208.087097 827.291626,210.407120 
	C843.340820,245.241089 859.539246,280.006500 875.529785,314.867279 
	C892.567505,352.011200 909.549072,389.181915 926.324585,426.444733 
	C945.487488,469.010620 964.574219,511.612701 983.358704,554.346436 
	C998.323669,588.390808 1012.898621,622.608398 1027.395874,656.855286 
	C1031.402954,666.321228 1034.538818,676.157715 1037.995972,685.852112 
	C1038.350098,686.844971 1038.164429,688.030273 1037.633057,689.398865 
	C1036.366577,689.757263 1035.696045,689.843079 1034.782959,689.846069 
	C1033.692261,689.865906 1032.844116,689.968628 1031.538330,690.010315 
	C1025.101562,690.341919 1019.120667,690.709290 1013.143616,691.131287 
	C976.798462,693.697205 940.474304,696.616028 904.104126,698.754822 
	C867.381287,700.914246 830.627075,702.727539 793.861633,703.932007 
	C739.454651,705.714355 685.031616,707.180359 630.603882,708.095947 
	C594.979126,708.695312 559.328674,708.441711 523.699524,707.912903 
	C488.417572,707.389221 453.145630,706.113403 417.873962,705.017822 
	C404.241913,704.594421 390.622650,703.759521 376.741821,702.955872 
	C376.184174,702.537964 375.882263,702.276428 375.619324,701.613037 
	C371.681976,680.519226 368.253448,659.703796 363.624451,639.158875 
	C350.802521,582.251648 334.194458,526.414062 314.369873,471.559418 
	C294.957001,417.843903 274.052551,364.720398 249.849701,312.676239 
	C249.665344,312.072540 249.416809,311.766907 249.107971,311.132965 
	C247.978958,308.545837 246.910217,306.287018 245.767365,303.677307 
	C239.174286,289.557953 232.819595,275.708649 226.092377,262.042725 
	C218.646744,246.917282 210.860291,231.959641 203.222244,216.928925 
z"/>
					<path id="1" style="cursor: pointer; fill:#ffffff; stroke:black;" 
	d="
M1039.386719,693.753540 
	C1040.046875,728.024780 1040.564331,762.051208 1041.175781,796.538086 
	C1041.250854,797.453979 1041.231934,797.909607 1041.339478,798.686340 
	C1041.759766,844.651672 1042.038940,890.295959 1042.354370,935.939941 
	C1042.497192,956.598267 1042.862793,977.255920 1042.870361,997.914001 
	C1042.917725,1127.887207 1042.997803,1257.860596 1042.841797,1387.833740 
	C1042.776611,1442.142212 1042.193481,1496.450317 1041.778809,1550.758179 
	C1041.557495,1579.743896 1041.318115,1608.730591 1040.860962,1637.713257 
	C1040.290894,1673.851318 1039.644775,1709.989502 1038.735474,1746.120361 
	C1038.023438,1774.414062 1037.091064,1802.706909 1035.800537,1830.979370 
	C1035.172241,1844.747314 1033.439209,1858.462769 1032.346069,1872.213135 
	C1032.061401,1875.793579 1030.345093,1877.646484 1026.934082,1878.240723 
	C1012.049377,1880.834351 997.234253,1883.943481 982.270386,1885.940552 
	C957.712891,1889.218384 933.082520,1891.997314 908.440491,1894.582153 
	C882.465027,1897.306885 856.441467,1899.575562 830.435669,1902.008301 
	C813.048340,1903.634888 795.662842,1905.288452 778.262756,1906.768066 
	C760.688354,1908.262329 743.100037,1909.596924 725.512390,1910.929688 
	C700.790283,1912.803223 676.066223,1914.651855 651.337219,1916.431763 
	C634.081970,1917.673950 616.820129,1918.829224 599.555786,1919.939697 
	C579.621643,1921.221802 559.684631,1922.464722 539.743042,1923.624268 
	C524.954163,1924.484131 510.162964,1925.380615 495.360168,1925.906738 
	C474.898773,1926.634155 454.427185,1927.114014 433.956329,1927.515015 
	C427.640228,1927.638794 421.297821,1927.349487 414.996521,1926.868652 
	C412.287750,1926.661743 408.601868,1927.185059 408.151489,1921.943848 
	C407.730286,1910.836792 407.496185,1900.545044 406.950531,1890.270020 
	C406.678345,1885.145142 405.836212,1880.050659 405.207336,1874.631104 
	C405.175201,1873.902710 405.187622,1873.485474 405.518555,1872.793457 
	C405.498566,1861.367798 405.160034,1850.216919 404.869537,1838.599365 
	C404.362366,1825.656372 403.711975,1813.183716 403.269867,1800.703491 
	C401.967255,1763.931885 400.680603,1727.159424 399.515442,1690.383423 
	C398.545166,1659.757568 397.804779,1629.124512 396.873413,1598.497314 
	C395.653717,1558.388428 394.252289,1518.284912 393.104858,1478.173950 
	C392.090759,1442.723877 391.313873,1407.266846 390.439087,1371.812744 
	C389.395874,1329.532104 388.290527,1287.252808 387.340027,1244.969971 
	C386.363312,1201.519775 385.585754,1158.065063 384.635529,1114.614258 
	C383.976562,1084.481689 383.110046,1054.353638 382.448151,1024.221191 
	C381.372894,975.271973 380.338287,926.321655 379.382233,877.369934 
	C378.530304,833.748718 377.827972,790.124695 377.013641,746.502747 
	C376.830505,736.691467 376.456329,726.883728 376.267456,716.682373 
	C376.364777,713.346130 376.364777,710.401917 376.364777,707.036987 
	C597.707520,718.017517 818.141541,710.645142 1038.344360,692.825317 
	C1038.644287,693.053162 1038.944092,693.281006 1039.386719,693.753540 
z"/>
					`,
        },
      ],
    ],

    listColorQuestion: [{ color: 'PURPLE' }, { color: 'ORANGE' }, { color: 'GREEN' }, { color: 'YELLOW' }, { color: 'BLUE' }, { color: 'RED' }],
    userAnswers: [],
  },
}

export default json
