import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterfaceV2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P17-E1",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Three-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/9.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/11.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Three-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/14.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Three-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E1/16.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P17-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Four-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/10.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Four-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Four-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/14.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E2/15.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P17-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // [
      // 	{
      // 		url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/1.jpg',
      // 		audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3',
      // 	},
      // ],
      // [
      // 	{
      // 		url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/2.jpg',
      // 		audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Five-ducks.mp3',
      // 	},
      // ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/3.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Five-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/10.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Five-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Five-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/14.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/16.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Five-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/17.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E3/18.jpg",
        },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P17-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    isAllowSubmit: false,
    questionImage: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page17/E4/1.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/Track-5.mp3",
          status_audio: 1,
        },
      ],
    ],
  },
};

export default json;
