import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P69-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/What-can-you-see.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/What-can-you-see.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/7.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/8.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/10.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/11.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-grass.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/12.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-vegetables.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/15.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/16.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/17.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-grass.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/19.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-vegetables.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E1/21.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P69-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E2/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P69-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E3/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P69-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E4/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-grass.mp3",
        },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P69-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page69/E5/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-vegetables.mp3",
        },
      ],
    ],
  },
};

export default json;
