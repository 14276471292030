import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U8-P82-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page82/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "67px",
              left: "523px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "205px",
              left: "523px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "344px",
              left: "523px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "484px",
              left: "523px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "621px",
              left: "523px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "67px",
              left: "706px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "205px",
              left: "706px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "344px",
              left: "706px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "484px",
              left: "706px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "621px",
              left: "706px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "purple",
            },
          },
        ],
        answers: ["0-6", "1-8", "2-9", "3-5", "4-7"],
        initialValue: ["0-6"],
      },
      Layout: `
          <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page82/E1/1.jpg' style="max-width: 90%;" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
    },
  },
};

export default json;
