import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P29-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E1/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather-Its-rainy.mp3",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P29-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E2/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather-Its-windy.mp3",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P29-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E3/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather-Its-snowy.mp3",
        },
      ],
    ],
  },
};

export default json;

// import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

// const json = {
// 	1: {
// 		// Exercise num
// 		unit: 'Unit 3',
// 		id: 'LQTAMN2-U3-P29-E1',
// 		audio: '',
// 		video: '',
// 		component: D1,
// 		recorder: true,
// 		isAllowSubmit: false,
// 		questionImage: [
// 			// Row
// 			[
// 				// Column1
// 				{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E1/2.jpg' },
// 				{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E1/1.jpg' },
// 			],
// 		],
// 	},
// 	2: {
// 		// Exercise num
// 		unit: 'Unit 3',
// 		id: 'LQTAMN2-U3-P29-E2',
// 		audio: '',
// 		video: '',
// 		component: D1,
// 		recorder: true,
// 		isAllowSubmit: false,
// 		questionImage: [
// 			// Row
// 			[
// 				// Column1
// 				{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E2/1.jpg' },
// 			],
// 		],
// 	},
// 	3: {
// 		// Exercise num
// 		unit: 'Unit 3',
// 		id: 'LQTAMN2-U3-P29-E3',
// 		audio: '',
// 		video: '',
// 		component: D1,
// 		recorder: true,
// 		isAllowSubmit: false,
// 		questionImage: [
// 			// Row
// 			[
// 				// Column1
// 				{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E3/6.jpg' },
// 				{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page29/E3/1.jpg' },
// 			],
// 		],
// 	},
// }

// export default json
