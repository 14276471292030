import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDotsV2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN2-U1-P11-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],
    imagesQuestion: [
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/3.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-bird.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/5.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-cat.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/7.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-chicken.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/9.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-dog.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/12.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-cat.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/14.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-dog.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/16.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-bird.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/18.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-chicken.mp3",
          status_audio: 0,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page11/E1/20.jpg",
        },
      ],
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "469px",
              left: "-225px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "469px",
              left: "-88px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "469px",
              left: "158px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "469px",
              left: "410px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "370px",
              left: "-223px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "392px",
              left: "0px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "179px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "401px",
              left: "427px",
              width: 20,
              height: 20,
              width: 20,
              height: 20,
              borderRadius: "50%",
              zIndex: 9,
              // backgroundColor: 'purple',
            },
          },
        ],
        answers: ["0-5", "1-7", "2-4", "3-6"],
        initialValue: ["0-5"],
      },
      Layout: `
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          
      `,
    },
  },
};

export default json;
