import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "LQTAMN2-U8-P81-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E1/Key/answerKey.png",
    // titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/19.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/1.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/3.jpg",
          input: true,
          answer: "c",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/4.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/5.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/7.jpg",
          input: true,
          answer: "a",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/8.jpg",
        },
      ],
      [
        // Column

        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/9.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/11.jpg",
          input: true,
          answer: "d",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/12.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/13.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/15.jpg",
          input: true,
          answer: "b",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/16.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/17.jpg",
        },
      ],
    ],

    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
