import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN2-U5-P45-E1",
    audio: "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/Track-11.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/3.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/Track-11.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/4.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/Track-11.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/6.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/10.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-12-details/I-like-milk.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/12.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/14.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/16.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/17.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/19.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-12-details/I-like-milk.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/20.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/21.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/22.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/23.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/24.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/26.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/27.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/28.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/29.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/30.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/31.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/32.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/33.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/34.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E1/35.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN2-U5-P45-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-12-details/I-like-milk.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-12-details/I-like-milk.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/12.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E2/15.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "LQTAMN2-U5-P45-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/11.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },

        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/14.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/16.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/What-do-you-like.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/19.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/20.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/22.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/23.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/24.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-11-details/I-dont-like-juice.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page45/E3/25.jpg",
        },
      ],
    ],
  },
};

export default json;
