import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P16-E1",
    audio: "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Track-4.mp3",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/3.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Track-4.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/4.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Track-4.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/6.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E1/7.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P16-E2",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/1.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/One-duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/6.jpg",
        },
      ],
      [
        // Column3
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E2/7.jpg",
        },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P16-E3",
    audio: "",
    video: "",
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/1.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Two-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/How-many-ducks-are-there.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/6.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-4-details/Two-ducks.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/9.jpg",
        },
      ],
      [
        // Column2
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page16/E3/10.jpg",
        },
      ],
    ],
  },
};

export default json;
