import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "LQTAMN2-U6-P62-E1",
    audio:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/Audio/G6 SB Track 2.02.WAV",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/Key/answerKey.png",
    component: DesignUnderLine,
    titleQuestion: [
      {
        num: "1",
        title: `Work in pairs. Answer questions 1–6 in the quiz.`,
        color: "#E26D28",
      },
    ],
    questionImage: [
      // Row

      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/02.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/03.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/04.jpg",
          input: 1,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/05.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/06.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/07.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/08.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/09.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/11.jpg",
          input: 2,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/14.jpg",
          input: 2,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/16.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/17.jpg",
          input: 3,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/19.jpg",
          input: 3,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/20.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/21.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/22.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/23.jpg",
          input: 4,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/24.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/26.jpg",
          input: 4,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/27.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/28.jpg",
          input: 5,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/29.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/30.jpg",
          input: 5,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/31.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/32.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/33.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/34.jpg",
          input: 6,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/35.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/36.jpg",
          input: 6,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/37.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/38.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/39.jpg",
          input: 7,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/40.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/41.jpg",
          input: 7,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/42.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/43.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/44.jpg",
          input: 8,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/45.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/46.jpg",
          input: 8,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/47.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/48.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/49.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/50.jpg",
          input: 9,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/51.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/52.jpg",
          input: 9,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/53.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1/54.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/01.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/02.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/03.jpg",
          input: 10,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/04.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/05.jpg",
          input: 10,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/06.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/07.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/08.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/09.jpg",
          input: 11,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/11.jpg",
          input: 11,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/12.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/14.jpg",
          input: 12,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/15.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/16.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/17.jpg",
          input: 12,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/18.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/19.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/20.jpg",
          input: 13,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/21.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/22.jpg",
          input: 13,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/23.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/24.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/26.jpg",
          input: 14,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/27.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/28.jpg",
          input: 14,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/29.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/30.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/31.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/32.jpg",
          input: 15,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/33.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/34.jpg",
          input: 15,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/35.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/36.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/37.jpg",
          input: 16,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/38.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/39.jpg",
          input: 16,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/40.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/41.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/42.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/43.jpg",
          input: 17,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/44.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/45.jpg",
          input: 17,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/46.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/47.jpg",
          input: 18,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/48.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/49.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/50.jpg",
          input: 18,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/51.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/52.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/53.jpg",
          input: 19,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/54.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/55.jpg",
          input: 19,
          isCorrect: true,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/56.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page62/E1-2/57.jpg",
        },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
