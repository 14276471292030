import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P61-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page61/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "75px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "288px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "519px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "362px",
              left: "767px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "223px",
              left: "75px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "223px",
              left: "288px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "223px",
              left: "519px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "223px",
              left: "767px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F8931D",
            },
          },
        ],
        answers: ["1-4", "3-5", "0-6", "2-7"],
        initialValue: ["1-4"],
      },
      Layout: `
          <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page61/E1/1.jpg' style="max-width: 90%;" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
    },
  },
};

export default json;
// import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

// const json = {
// 	1: {
// 		// Exercise num
// 		unit: 'Unit 6',
// 		id: 'LQTAMN2-U6-P61-E1',
// 		audio: '',
// 		video: '',
// 		exerciseKey: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page61/E1/Key/answerKey.png',
// 		component: MatchDots,
// 		titleQuestion: [
// 			{
// 				num: '',
// 				title: '',
// 				color: '',
// 			},
// 		],

// 		question: {
// 			DrawLines: {
// 				multipleLine: false,
// 				boxMatch: [
// 					//1
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '344px',
// 							left: '125px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//2
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '447px',
// 							left: '340px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//3
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '344px',
// 							left: '338px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//4
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '468px',
// 							left: '833px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//5
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '344px',
// 							left: '586px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//6
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '486px',
// 							left: '123px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//7
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '344px',
// 							left: '832px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 					//8
// 					{
// 						boxMatchStyle: {
// 							position: 'absolute',
// 							top: '486px',
// 							left: '585px',
// 							width: 20,
// 							height: 20,
// 							borderRadius: '50%',
// 							// backgroundColor: 'purple',
// 						},
// 					},
// 				],
// 				answers: ['0-1', '2-3', '4-5', '6-7'],
// 				initialValue: ['0-1'],
// 			},
// 			Layout: `
//           <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page61/E1/1.jpg' style="max-width: 90%;" />
//           <input id='0' type= 'boxMatch' />
//           <input id='1' type= 'boxMatch' />
//           <input id='2' type= 'boxMatch' />
//           <input id='3' type= 'boxMatch' />
//           <input id='4' type= 'boxMatch' />
//           <input id='5' type= 'boxMatch' />
//           <input id='6' type= 'boxMatch' />
//           <input id='7' type= 'boxMatch' />
//       `,
// 		},
// 	},
// }

// export default json
