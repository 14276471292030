import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P30-E1",
    audio: "https://cdn.sachso.edu.vn/mn2/Audios/Track-8-details/Track-8.mp3",
    video:
      "https://cdn.sachso.edu.vn/mn2/Videos/Unit 3_Track 8_How's the weather.mp4",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/3.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-8-details/Track-8.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/4.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-8-details/Track-8.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/5.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/6.jpg",
        },
      ],
    ],
  },
  // 1: {
  // 	// Exercise num
  // 	unit: 'Unit 3',
  // 	id: 'LQTAMN2-U3-P30-E1',
  // 	audio: '',
  // 	video: '',
  // 	component: UI,
  // 	recorder: true,
  // 	isAllowSubmit: false,
  // 	questionImage: [
  // 		// Row
  // 		[
  // 			// Column1
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/1.jpg' },
  // 		],
  // 		[
  // 			// Column1
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/2.jpg' },
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/3.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/4.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-8-details/Track-8.mp3',
  // 			},
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/5.jpg' },
  // 		],
  // 		[
  // 			// Column1
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page30/E1/6.jpg' },
  // 		],
  // 	],
  // },
};

export default json;
