import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P31-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page31/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "490px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "233px",
              left: "755px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "239px",
              left: "490px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "704px",
              left: "755px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "490px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "551px",
              left: "755px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "551px",
              left: "490px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "130px",
              left: "755px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "696px",
              left: "490px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
          //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "400px",
              left: "755px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#F7941D",
            },
          },
        ],
        answers: ["0-1", "2-3", "4-5", "6-7", "8-9"],
        initialValue: ["0-1"],
      },
      Layout: `
          <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page31/E1/a.jpg' style="max-width: 90%;" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          
        
         
      `,
    },
  },
};

export default json;
