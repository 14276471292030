import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    audio: "",
    video: "",
    component: D1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E1/Key/answerKey.png",
    // titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/19.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/1.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/3.jpg",
          input: true,
          answer: "c",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/4.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/5.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/7.jpg",
          input: true,
          answer: "a",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/8.jpg",
        },
      ],
      [
        // Column

        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/9.jpg",
        },
      ],

      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/10.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/11.jpg",
          input: true,
          answer: "d",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/12.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/13.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/15.jpg",
          input: true,
          answer: "b",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/16.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E1/17.jpg",
        },
      ],
    ],

    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  2: {
    // Exercise num
    audio:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E2/Audio/G6 SB Track 2.05.mp3",
    video: "",
    component: D1,
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E2/Key/answerKey.png",
    // titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/1.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/3.jpg",
          input: true,
          answer: "isn't",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/4.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/6.jpg",
          input: true,
          answer: "healthy",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/7.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/9.jpg",
          input: true,
          answer: "eat",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/10.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/12.jpg",
          input: true,
          answer: "afternoons",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/13.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/14.jpg",
          input: true,
          answer: "after",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/page64/E2/15.jpg",
        },
      ],
    ],

    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  3: {
    // Exercise num
    audio: "",
    video: "",
    component: T2,
    titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  4: {
    // Exercise num
    audio: "",
    video: "",
    component: T2,
    titleImage: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page64/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
