import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P24-E1",
    audio: "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Track-6.mp3",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/3.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Track-6.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/4.jpg",
          audioMainUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Track-6.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-bird-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/7.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-bird-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/11.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/12.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/13.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-dog-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/14.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/15.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/16.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-dog-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/17.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/18.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/19.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/20.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/21.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-chicken-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/22.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/23.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-duck.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/24.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/25.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/26.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/27.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/28.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-chicken-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/29.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/30.jpg",
        },
      ],
    ],
  },
  // 1: {
  // 	// Exercise num
  // 	unit: 'Unit 2',
  // 	id: 'LQTAMN2-U2-P124-E1',
  // 	audio: '',
  // 	video: '',
  // 	component: D1,
  // 	recorder: true,
  // 	isAllowSubmit: false,
  // 	questionImage: [
  // 		// Row
  // 		[
  // 			// Column1
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/1.jpg' },
  // 		],
  // 		[
  // 			// Column1
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/2.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/3.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Track-6.mp3',
  // 			}, //track 6
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/4.jpg' },
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/5.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/6.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-bird-2.mp3',
  // 			}, //track bird
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/7.jpg' },
  // 		],
  // 		[
  // 			// Column2
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/8.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/9.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-bird-2.mp3',
  // 			}, //track bird
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/10.jpg' },
  // 		],
  // 		[
  // 			// Column3
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/11.jpg' },
  // 		],
  // 		[
  // 			// Column4
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/12.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/13.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-dog-2.mp3',
  // 			}, // track dog
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/14.jpg' },
  // 		],
  // 		[
  // 			// Column4
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/15.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/16.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-chicken-2.mp3',
  // 			}, // track chicken
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/17.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/18.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-duck.mp3',
  // 			}, // track duck
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/19.jpg' },
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/20.jpg' },
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/21.jpg' },
  // 		],
  // 		[
  // 			// Column5
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/22.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/23.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-chicken-2.mp3',
  // 			}, // track chicken
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/24.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/25.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-cat-2.mp3',
  // 			}, // track cat
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/26.jpg' },
  // 		],
  // 		[
  // 			// Column6
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/27.jpg' },
  // 			{
  // 				url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/28.jpg',
  // 				audioUrl: 'https://cdn.sachso.edu.vn/mn2/Audios/Track-6-details/Im-a-cat-2.mp3',
  // 			}, // track cat
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/29.jpg' },
  // 		],
  // 		[
  // 			// Column6
  // 			{ url: 'https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page24/E1/30.jpg' },
  // 		],
  // 	],
  // },
};

export default json;
