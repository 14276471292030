import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P70-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/4.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/6.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/7.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/8.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/9.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/10.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/11.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/12.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/13.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/14.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/15.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/16.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-tree.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/17.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/18.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/19.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/20.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/21.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-grass.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/22.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/23.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/24.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-flowers.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/25.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/26.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/27.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-grass.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/28.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/29.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-vegetables.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/30.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/31.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/32.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-15-details/I-can-see-the-vegetables.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/33.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page70/E1/34.jpg",
        },
      ],
    ],
  },
};

export default json;
