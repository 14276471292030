import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN2-U1-P12-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/1.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-cat.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-dog.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-bird.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios//Track-2-details/Im-a-chicken.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/7.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page12/E1/8.jpg",
        },
      ],
    ],
  },
};

export default json;
