import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "LQTAMN2-U6-P56-E1",
    audio: "",
    video: "",
    exerciseKey:
      "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page56/E1/Key/answerKey.png",
    component: MatchDots,
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "278px",
              left: "393px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "361px",
              left: "637px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "278px",
              left: "574px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "361px",
              left: "877px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "278px",
              left: "782px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "361px",
              left: "150px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "278px",
              left: "950px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
          //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "361px",
              left: "399px",
              width: 20,
              height: 20,
              borderRadius: "50%",
              // backgroundColor: 'purple',
            },
          },
        ],
        answers: ["0-1", "2-3", "4-5", "6-7"],
        initialValue: ["6-7"],
      },
      Layout: `
          <img src='https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page56/E1/1.jpg' style="max-width: 90%;" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
    },
  },
};

export default json;
