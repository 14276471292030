// import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'
import UI from "../../components/ExcerciseTypes/Design/UserInterfaceV2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "LQTAMN2-U1-P14-E1",
    audio: "",
    video: "",
    component: UI,
    //recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column1
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page14/E1/1.jpg",
          audioImgUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-2-details/Im-a-cat.mp3",
          status_audio: 0,
        },
      ],
    ],
  },
};

export default json;
