// import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit'

import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "LQTAMN2-U4-P44-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    recorder: true,
    totalInput: 1,
    titleQuestion: [
      {
        num: "",
        title: ``,
        color: "",
      },
    ],
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/1.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/3.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/4.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/5.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/6.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/7.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/9.jpg",
          input: 1,
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/10.jpg",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page27/E1/11.jpg",
        },
      ],
    ],
  },
};

export default json;
