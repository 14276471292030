import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "LQTAMN2-U2-P20-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/1.jpg",
        },
      ],
      [
        // Column
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/One-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/Two-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/Three-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/6.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/Four-2.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page20/E1/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-5-details/Five-2.mp3",
        },
      ],
    ],
  },
};

export default json;
