import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P34-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/1.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/2.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/3.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-sunny.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-sunny.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-sunny.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E1/8.jpg",
        },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "LQTAMN2-U3-P34-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [
      // Row
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/1.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/2.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/3.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/4.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-snowy.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/5.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-snowy.mp3",
        },
      ],
      [
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/6.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/7.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Hows-the-weather.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/8.jpg",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/9.jpg",
          audioUrl:
            "https://cdn.sachso.edu.vn/mn2/Audios/Track-7-details/Its-snowy.mp3",
        },
        {
          url: "https://cdn.sachso.edu.vn/mn2/img/FriendsPlus/Page34/E2/10.jpg",
        },
      ],
    ],
  },
};

export default json;
